<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Vendedores - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
             
                <b-col md="2">
                  <b-form-group label="Tipo de Documento :">
                    <b-form-select disabled ref="city" v-model="seller.document_type" :options="document_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input disabled v-model="seller.document_number" class="form-control" ></b-form-input>
                      <b-input-group-append>
                        <b-button disabled variant="primary"  @click="Searchseller"><b-icon icon="search"></b-icon></b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Nombres / Razón Social:" required>
                    <b-form-input disabled type="text" ref="name" v-model="seller.name" placeholder="Ingrese su nombre"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Usuario :" required>
                    <b-form-input disabled type="text" v-model="seller.user"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Teléfono :" required>
                    <b-form-input disabled type="text" v-model="seller.phone"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="7">
                  <b-form-group label="Email :" required>
                    <b-form-input disabled type="email"  v-model="seller.email"></b-form-input>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Estado :">
                    <b-form-select disabled v-model="seller.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>
         

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link
                    class="btn form-control btn-primary"
                    :to="{ path: '/vendedores/listar' }"
                    append
                    >REGRESAR</b-link
                  >
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  props:["id_seller"],
  components:{
    vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module:'Seller',
      seller: {
        document_type: "1",
        document_number: "",
        name: "",
        user: "",
        phone: "",
        email: "",
        state: 1,
      },
      mubigee:null,
      ubigee: [],
      contries: [],
      cities: [],
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,
        user: false,
      },
      error_document_number :'',
      validate: false,
    };
  },
  mounted() {
    this.ViewSeller();
  },
  methods: {
    ViewSeller,
    Searchseller,
    Editar,
    Validate,
  },

  computed: {
    
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ViewSeller() {
  let me  = this;
  let id_seller = je.decrypt(me.id_seller);
  let url = me.url_base + "seller/view/" + id_seller;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
      module: me.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.seller.id_seller = response.data.result.id_seller;
        me.seller.document_type = response.data.result.document_type;
        me.seller.document_number = response.data.result.document_number;
        me.seller.name = response.data.result.name;
        me.seller.user = response.data.result.user;
        me.seller.phone = response.data.result.phone;
        me.seller.email = response.data.result.email;
        me.seller.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}


function Searchseller() {

  let me = this;
  if (me.seller.document_type == 1) {
      if (me.seller.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.seller.document_number).then((data) => {
          if (data.status == 200) {
            me.seller.name = data.razon_social;
          }else{
            me.seller.name = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.seller.document_type == 6) {
      if (me.seller.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.seller.document_number).then((data) => {
          if (data.status == 200) {
            me.seller.name = data.razon_social;
          }else{
            me.seller.name = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
  
}

function Editar() {
  
  let me = this;
  let url = me.url_base + "seller/edit";
  let data = me.seller;
  me.isLoading = true;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: 2,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: 'Se ha modificado el vendedor', timer: 3000,})
      }else if(response.data.status == 400){
        Swal.fire({ icon: 'warning', text: 'El vendedor ingresado ya se encuentra registrado', timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

function Validate() {

  

  this.validate = false;
  if (this.seller.document_type == 1) {
    this.errors.document_number = this.seller.document_number.length != 8 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 8 digitos';
  }else if(this.seller.document_type == 6){
    this.errors.document_number = this.seller.document_number.length != 11 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 11 digitos';
  }else if(this.seller.document_type == 4){
    this.errors.document_number = this.seller.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else if(this.seller.document_type == 7){
    this.errors.document_number = this.seller.document_number.length != 12 ? true : false;
    this.error_document_number = 'El nro de ducumento debe contener 12 digitos';
  }else{
    this.errors.document_number = this.seller.document_number.length == 0 ? true : false;
    this.error_document_number = 'Ingrese un nro de documento';
  }

  this.errors.document_type = this.seller.document_type.length == 0 ? true : false;
  this.errors.name = this.seller.name.length == 0 ? true : false;
  this.errors.user = this.seller.user.length == 0 ? true : false;

  if (this.errors.document_type) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.document_number) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.user) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  let me = this;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar el vendedor?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.Editar();
      }
    });

  }

}
</script>
